import React from 'react';
import './MobileTopUp.css';
import djezzyLogo from '../../../../assets/images/service/djezzy.png';
import ooredooLogo from '../../../../assets/images/service/ooredoo.png';
import mobilisLogo from '../../../../assets/images/service/mobilis.png';
import idoomLogo from '../../../../assets/images/service/idoom.png';  // Add this import
import { useTranslation } from 'react-i18next';

const MobileTopUp = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <section id="services" className="mobile-topup section" style={isArabic ? { direction: 'rtl' } : {}}>
      <div className="container">
        <div className="row">
          <div className="col-12">
          <div className="ud-section-title-Features  mx-auto text-center">
              <span className="wow zoomIn Services"> {t("services")}</span>
            </div>
            <div className="mobile-topup-content">
              <div className="content-left wow fadeInUp" data-wow-delay="0.2s">
                <h2>{t("Mobile Top-Up")}</h2>
                <p>{t("Instant recharge for all operators")}</p>
                <div className="features-list">
                  <div className="feature-item">
                    <span className="feature-icon-mobile-topup">✓</span>
                    <span className="feature-text">{t("Instant Processing")}</span>
                  </div>
                  <div className="feature-item">
                    <span className="feature-icon-mobile-topup">✓</span>
                    <span className="feature-text">{t("24/7 Available")}</span>
                  </div>
                  <div className="feature-item">
                    <span className="feature-icon-mobile-topup">✓</span>
                    <span className="feature-text">{t("Secure Transactions")}</span>
                  </div>
                  <div className="feature-item bonus-feature">
                    <span className="feature-icon-mobile-topup">🎁</span>
                    <span className="feature-text">{t("Bonus 1%")}</span>
                  </div>
                </div>
              </div>
              <div className="content-right wow fadeInUp" data-wow-delay="0.4s">
                <div className="operators-wrapper">
                  <div className="operator djezzy-operator">
                    <img src={djezzyLogo} alt="Djezzy" />
                    <div className="operator-glow"></div>
                  </div>
                  <div className="operator ooredoo-operator">
                    <img src={ooredooLogo} alt="Ooredoo" />
                    <div className="operator-glow"></div>
                  </div>
                  <div className="operator mobilis-operator">
                    <img src={mobilisLogo} alt="Mobilis" />
                    <div className="operator-glow"></div>
                  </div>
                  <div className="operator idoom-operator">
                    <img src={idoomLogo} alt="Idoom" />
                    <div className="operator-glow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileTopUp;
