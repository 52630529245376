import React from 'react';
import './BillPayment.css';
import adeImage from '../../../../assets/images/service/ade.png';
import sonelgazImage from '../../../../assets/images/service/Sonlgaz.png';
import { useTranslation } from 'react-i18next';

const BillPayment = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <section className="bill-payment section" style={isArabic ? { direction: 'rtl' } : {}}>
      <div className="container">
        <div className="bill-payment-cards">
          <div className="bill-payment-info wow fadeInUp" data-wow-delay="0.2s">
            <h2>{t("Easy Payment of Bills and Internet")}</h2>
            <p>{t("Settle your electricity, gas (Sonelgaz), ADE bills with just a few clicks")}</p>
          </div>
          <div className="providers-grid wow fadeInUp" data-wow-delay="0.4s">
            <div className="provider-box sonelgaz-box">
              <img src={sonelgazImage} alt="Sonelgaz" />
              <div className="provider-hover-effect"></div>
            </div>
            <div className="provider-box ade-box">
              <img src={adeImage} alt="ADE" />
              <div className="provider-hover-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BillPayment;
