import React, { useState } from 'react';
import "./Contact.css";
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    message: ''
  });

  // التحقق إذا كانت لغة الصفحة هي العربية
  const isArabic = i18n.language === 'ar';

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!formData.user_name || !formData.user_email || !formData.user_phone || !formData.message) {
      toast.error(`${t('fill_all_fields')}`);
      return;
    }
    try {
      const result = await emailjs.sendForm('service_pruxncp', 'template_jr4iv48', e.target, 'C0v8G08C33cQ8-cVQ');
      console.log(result.text);
      toast.success(`${t('message_sent_success')}`);
    } catch (error) {
      console.error(error.text);
      toast.error(`${t('failed_to_send_message')}`);
    }

    e.target.reset();
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <section id="contact" className="ud-contact" style={isArabic ? {  direction: 'rtl' } : {}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="ud-contact-content-wrapper">
                <div className="contact-title wow fadeInLeft" data-wow-delay=".4s">
                  <span>{t("Contact Us")}</span>
                  <h2>
                    {t("Please feel free to contact us!")}
                  </h2>
                </div>
                <div className="ud-contact-info-wrapper wow fadeInLeft" data-wow-delay=".4s">
                  <div className="ud-single-info ">
                    <div className="ud-info-icon">
                      <i className="lni lni-map-marker" style={isArabic ? { transform: 'scaleX(-1)' } : {}}></i>
                    </div>
                    <div className="ud-info-meta">
                      <h5>{t("Our Location")}</h5>
                      <p>{t("Kesraoui Hachemi Street in the city center next to El Badr Bank, Mechiria, Naama, Algeria")}</p>
                    </div>
                  </div>
                  <div className="ud-single-info">
                    <div className="ud-info-icon" >
                      <i className="lni lni-phone" style={isArabic ? { transform: 'scaleX(-1)',marginLeft:"8px" } : {}}></i>
                    </div>
                    <div className="ud-info-meta">
                      <h5>{t("How Can We Help?")}</h5>
                      <p>{t("+213 673 93 78 94")}</p>
                      <p>contact@SofizPay.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="ud-contact-form-wrapper wow fadeInUp" data-wow-delay=".6s">
                <h3 className="ud-contact-form-title">{t("Send us a Message")}</h3>
                <form className="ud-contact-form" onSubmit={sendEmail}>
                  <div className="ud-form-group">
                    <label htmlFor="fullName">{t("Full Name")}*</label>
                    <input
                      type="text"
                      name="user_name"
                      onChange={handleInputChange}
                      value={formData.user_name}
                      placeholder={t("mohammed ")}
                    />
                  </div>
                  <div className="ud-form-group">
                    <label htmlFor="email">{t("Email")}*</label>
                    <input   
                      type="email"
                      name="user_email"
                      onChange={handleInputChange}
                      value={formData.user_email}
                      placeholder={t("example@gmail.com")}
                    />
                  </div>
                  <div className="ud-form-group">
                    <label htmlFor="phone">{t("Phone")}*</label>
                    <input
                    
                      type="text"
                      name="user_phone"
                      onChange={handleInputChange}
                      value={formData.user_phone}
                      placeholder={t("+213 673 93 78 94")}
                    />
                  </div>
                  <div className="ud-form-group">
                    <label htmlFor="message">{t("Message")}*</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      rows="1"
                      onChange={handleInputChange}
                      placeholder={t("type your message here")}
                    ></textarea>
                  </div>
                  <div className="ud-form-group mb-0">
                    <button type="submit" className="ud-main-btn">
                      {t("Send Message")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </>
  );
};

export default Contact;
// import React, { useState } from 'react';
// import "./Contact.css";
// import { useTranslation } from 'react-i18next';
// import axios from 'axios'; // استيراد مكتبة Axios
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const Contact = () => {
//   const { t, i18n } = useTranslation();
//   const [formData, setFormData] = useState({
//     user_name: '',
//     user_email: '',
//     user_phone: '',
//     message: ''
//   });

//   const isArabic = i18n.language === 'ar';

//   const sendEmail = async (e) => {
//     e.preventDefault();
//     if (!formData.user_name || !formData.user_email || !formData.user_phone || !formData.message) {
//       toast.error(`${t('fill_all_fields')}`);
//       return;
//     }

//     try {
//       const response = await axios.post('/contact', formData); 
//       console.log(response.data);
//       toast.success(`${t('message_sent_success')}`);
//     } catch (error) {
//       console.error(error);
//       toast.error(`${t('failed_to_send_message')}`);
//     }

//     setFormData({
//       user_name: '',
//       user_email: '',
//       user_phone: '',
//       message: ''
//     });
//   };

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   return (
//     <>
//       <section id="contact" className="ud-contact" style={isArabic ? { direction: 'rtl' } : {}}>
//         <div className="container">
//           <div className="row align-items-center">
//             <div className="col-xl-8 col-lg-7">
//               <div className="ud-contact-content-wrapper">
//                 <div className="contact-title wow fadeInLeft" data-wow-delay=".4s">
//                   <span>{t("Contact Us")}</span>
//                   <h2>
//                     {t("Please feel free to contact us!")}
//                   </h2>
//                 </div>
//                 <div className="ud-contact-info-wrapper wow fadeInLeft" data-wow-delay=".4s">
//                   <div className="ud-single-info ">
//                     <div className="ud-info-icon">
//                       <i className="lni lni-map-marker" style={isArabic ? { transform: 'scaleX(-1)' } : {}}></i>
//                     </div>
//                     <div className="ud-info-meta">
//                       <h5>{t("Our Location")}</h5>
//                       <p>{t("Kesraoui Hachemi Street in the city center next to El Badr Bank, Mechiria, Naama, Algeria")}</p>
//                     </div>
//                   </div>
//                   <div className="ud-single-info">
//                     <div className="ud-info-icon" >
//                       <i className="lni lni-phone" style={isArabic ? { transform: 'scaleX(-1)' } : {}}></i>
//                     </div>
//                     <div className="ud-info-meta">
//                       <h5>{t("How Can We Help?")}</h5>
//                       <p>{t("+213 673 93 78 94")}</p>
//                       <p>contact@SofizPay.com</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-xl-4 col-lg-5">
//               <div className="ud-contact-form-wrapper wow fadeInUp" data-wow-delay=".6s">
//                 <h3 className="ud-contact-form-title">{t("Send us a Message")}</h3>
//                 <form className="ud-contact-form" onSubmit={sendEmail}>
//                   <div className="ud-form-group">
//                     <label htmlFor="fullName">{t("Full Name")}*</label>
//                     <input
//                       type="text"
//                       name="user_name"
//                       onChange={handleInputChange}
//                       value={formData.user_name}
//                       placeholder={t("mohammed ")}
//                     />
//                   </div>
//                   <div className="ud-form-group">
//                     <label htmlFor="email">{t("Email")}*</label>
//                     <input   
//                       type="email"
//                       name="user_email"
//                       onChange={handleInputChange}
//                       value={formData.user_email}
//                       placeholder={t("example@gmail.com")}
//                     />
//                   </div>
//                   <div className="ud-form-group">
//                     <label htmlFor="phone">{t("Phone")}*</label>
//                     <input
                    
//                       type="text"
//                       name="user_phone"
//                       onChange={handleInputChange}
//                       value={formData.user_phone}
//                       placeholder={t("+213 673 93 78 94")}
//                     />
//                   </div>
//                   <div className="ud-form-group">
//                     <label htmlFor="message">{t("Message")}*</label>
//                     <textarea
//                       name="message"
//                       value={formData.message}
//                       rows="1"
//                       onChange={handleInputChange}
//                       placeholder={t("type your message here")}
//                     ></textarea>
//                   </div>
//                   <div className="ud-form-group mb-0">
//                     <button type="submit" className="ud-main-btn">
//                       {t("Send Message")}
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <ToastContainer position="bottom-right" autoClose={5000} />
//     </>
//   );
// };

// export default Contact;
