import React, { useEffect, useState } from 'react';
import Logo from '../../../../assets/images/logo/white-logo copy.png';
import LogoPink from '../../../../assets/images/logo/logo copy.png';
import 'bootstrap/dist/js/bootstrap.bundle';
import WOW from 'wow.js';
import Isotope from 'isotope-layout';
import "./Header.css"
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguafeSelector';

const Header = () => {
    const [isActive, setIsActive] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    const handleLanguageChange = (selectedLanguage) => {
        i18n.changeLanguage(selectedLanguage);
        setCurrentLanguage(selectedLanguage);
    };

    useEffect(() => {

        const handleScroll = () => {
            const scrollTop = window.scrollY;

            // You can adjust the scroll position threshold as needed
            if (scrollTop > 50) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        const iso = new Isotope('.portfolio-container', {
            itemSelector: '.portfolio-item',
            layoutMode: 'fitRows',
        });

        window.onscroll = function () {
            var header_navbar = document.querySelector('.navbar-area');
            var sticky = header_navbar.offsetTop;

            var logo = document.querySelector('.navbar-brand img');
            if (window.pageYOffset > sticky) {
                header_navbar.classList.add('sticky');
                logo.src = LogoPink; // Change here

            } else {
                header_navbar.classList.remove('sticky');
                logo.src = Logo; // Change here
            }

            var backToTo = document.querySelector('.scroll-top');
            if (backToTo) {
                if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                    backToTo.style.display = 'flex';
                } else {
                    backToTo.style.display = 'none';
                }
            }
        };

        function onScroll(event) {
            var sections = document.querySelectorAll('.page-scroll');
            var scrollPos =
                window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            for (var i = 0; i < sections.length; i++) {
                var currLink = sections[i];
                var val = currLink.getAttribute('href');
                // Remove leading forward slash if present
                val = val.replace(/^\//, '');
                var refElement = document.querySelector(val);
                if (refElement) {  // Add null check
                    var scrollTopMinus = scrollPos + 73;
                    if (
                        refElement.offsetTop <= scrollTopMinus &&
                        refElement.offsetTop + refElement.offsetHeight > scrollTopMinus
                    ) {
                        document.querySelector('.page-scroll').classList.remove('active');
                        currLink.classList.add('active');
                    } else {
                        currLink.classList.remove('active');
                    }
                }
            }
        }

        window.document.addEventListener('scroll', onScroll);

        var pageLink = document.querySelectorAll('.page-scroll');

        pageLink.forEach((elem) => {
            elem.addEventListener('click', (e) => {
                e.preventDefault();
                document.querySelector(elem.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth',
                    offsetTop: 1 - 60,
                });
            });
        });
        // تهيئة  لتفعيل الرسوم المتحركة عند التمرير WOW.js

        new WOW().init();

        let filterButtons = document.querySelectorAll('.portfolio-btn-wrapper button');
        filterButtons.forEach((e) =>
            e.addEventListener('click', (event) => {
                let filterValue = event.target.getAttribute('data-filter');
                iso.arrange({
                    filter: filterValue,
                });
            })
        );
        var elements = document.getElementsByClassName('portfolio-btn');
        for (var i = 0; i < elements.length; i++) {
            elements[i].onclick = function () {
                var el = elements[0];
                while (el) {
                    if (el.tagName === 'BUTTON') {
                        el.classList.remove('active');
                    }
                    el = el.nextSibling;
                }
                this.classList.add('active');
            };
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    const handleNavbarToggle = () => {
        setIsActive(!isActive);
    };
    const isArabic = i18n.language === 'ar';

    return (
        <>


            <header className={`header navbar-area${scrolling ? ' scrolled' : ''}`} style={isArabic ? { direction: 'rtl' } : {}} >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="nav-inner">
                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand" href="/">
                                        <img src={Logo} alt="Logo" />
                                    </a>
                                    <button
                                        className={`navbar-toggler mobile-menu-btn ${isActive ? 'active' : ''}`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                        aria-controls="navbarSupportedContent"
                                        aria-expanded="true"
                                        aria-label="Toggle navigation"
                                        onClick={handleNavbarToggle}
                                    >
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul id="nav" className="navbar-nav ms-auto">
                                            <li className="nav-item">
                                                <a href="#home" className="" aria-label="Toggle navigation">
                                                    {t('Home')}
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a href="#features" className="page-scroll" aria-label="Toggle navigation">
                                                    {t("Features")}
                                                </a>
                                            </li>

       <li className="nav-item">
                                                <a href="#services" className="page-scroll" aria-label="Toggle navigation">
                                                    {t("services")}
                                                </a>
                                            </li>
{/*                                      
                                            <li className="nav-item" >
                                                <a href="#testimonials" className="page-scroll" aria-label="Toggle navigation">
                                                    {t("Testimonial")}
                                                </a>
                                            </li> */}
                                            <li className="nav-item" >
                                                <a href="#faq" className="page-scroll" aria-label="Toggle navigation">
                                                    {t("FAQ")}
                                                </a>
                                            </li>
                                            <li className="nav-item" style={isArabic ? { marginLeft: '30px' } : {}}>
                                                <a href="#Intro" className="page-scroll" aria-label="Toggle navigation">
                                                    {t("Intro Vedio")}
                                                </a>
                                            </li>
                                     
                                            <li className="nav-item">
                                                <a href="#contact" className="page-scroll" aria-label="Toggle navigation">
                                                    {t("Contact Us")}
                                                </a>
                                            </li>
                                            {/* testimonials */}

                                        </ul>
                                        <LanguageSelector
                                            handleLanguageChange={handleLanguageChange}
                                            currentLanguage={currentLanguage}
                                        />

                                    </div>

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    );
};

export default Header;
