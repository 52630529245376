import React from 'react';
import './GameServices.css';
import pubgImage from '../../../../assets/images/service/pubg.jpg';
import freefireImage from '../../../../assets/images/service/freefire.jpg';
import { useTranslation } from 'react-i18next';

const GameServices = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <section id="services" className="game-services section" style={isArabic ? { direction: 'rtl' } : {}}>
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2 className='title-text'>{t("Game Services")}</h2>
              <p className='title-text'>{t("Top-up your favorite games easily and quickly")}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="single-service wow fadeInUp" data-wow-delay="0.2s">
              <div className="service-image">
                <img src={pubgImage} alt="PUBG" />
              </div>
              <h3>{t("PUBG Mobile")}</h3>
              <p>{t("Top-up your PUBG Mobile account with ease and enjoy the game to the fullest.")}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="single-service wow fadeInUp" data-wow-delay="0.4s">
              <div className="service-image">
                <img src={freefireImage} alt="FreeFire" />
              </div>
              <h3>{t("FreeFire")}</h3>
              <p>{t("Top-up your FreeFire account with ease and enjoy the game to the fullest.")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GameServices;
