import React from 'react';
import './Services.css';
import GameServices from '../GameServices/GameServices';
import MobileTopUp from '../MobileTopUp/MobileTopUp';
import BillPayment from '../BillPayment/BillPayment';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <section id="services" className="services-section" style={isArabic ? { direction: 'rtl' } : {}}>
              <MobileTopUp />
              <BillPayment />
      <GameServices />
    </section>
  );
};

export default Services;
